<template>load</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  setup() {
    const { params } = useRoute();
    const id = ref<string>(
      Array.isArray(params.id) ? params.id[0] : params.id.toString()
    );
    return {
      id
    };
  }
});
</script>
