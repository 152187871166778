
import { defineComponent, ref, watch } from 'vue';
import { Field, Form as VeeForm, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default defineComponent({
  name: 'errorRegistration',
  components: {
    Field,
    VeeForm,
    ErrorMessage
  },
  props: {
    allowPartly: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    const errorId = ref<string>();
    const errorDescription = ref<string>();
    const allErrors = ref<boolean>(false);
    const correctionType = ref<number>(props.allowPartly ? 1 : 2);
    const registreSchema = yup.object({
      allowClose: yup.boolean(),
      errorId: yup.string().required('Punkt skal udfyldes'),
      errorDescription: yup.string().required('Beskrivelse skal udfyldes'),
      correctionType: yup.number().when('allErrors', {
        is: true,
        then: yup.number().required()
      })
    });
    const onSubmit = (values: any, formState: any): void => {
      const note = allErrors.value
        ? ''
        : `${errorId.value} - ${errorDescription.value}`;
      emit('onAdd', note);
      formState.resetForm({
        values: {
          errorId: '',
          errorDescription: ''
        }
      });
    };
    const OnAllErrors = (): void => {
      emit('onDone');
    };
    watch(
      () => correctionType.value,
      () => {
        allErrors.value = !allErrors.value;
      }
    );
    return {
      errorId,
      errorDescription,
      correctionType,
      allErrors,
      registreSchema,
      onSubmit,
      OnAllErrors
    };
  }
});
