<template>
  <div class="row justify-content-md-center pt-4">
    <div class="col-md-6 mt-4">
      <h5>Der er sket en fejl vi beklagere</h5>
      <h6>Sagen blev ikke fundet !</h6>
      <a href="/">Til Portal</a>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return {};
  }
});
</script>
