
 
/*
 * view model for Portal.Data.ErrorCorrections.ErrorCorrectionNoteViewModel
 */

export default class ErrorCorrectionNoteViewModel  {

public id: number = 0;

public description: string = '';

public closedBy: string = '';

public closed: Date = new Date();

}