<template>
  <div class="text-start mt-4">
    <p>
      <em><slot></slot></em>
    </p>
    <template v-if="allowPartly">
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="inlineRadio1"
          value="1"
          v-model="correctionType"
        />
        <label class="form-check-label" for="inlineRadio1">
          Delvis Fejlretning
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="inlineRadio2"
          value="2"
          v-model="correctionType"
        />
        <label class="form-check-label" for="inlineRadio2">
          Fejlretning af alle fejl
        </label>
      </div>
      <div
        v-if="correctionType == 1"
        id="typeHelp"
        class="form-text text-warning"
      >
        Fejlretning vil ikke godkende anlægget
      </div>
      <div
        v-if="correctionType == 2"
        id="typeHelp"
        class="form-text text-success"
      >
        Fejlretning vil godkende anlægget
      </div>
    </template>
    <VeeForm
      as="div"
      id="registreErrorForm"
      v-slot="{ handleSubmit }"
      :validation-schema="registreSchema"
      v-if="correctionType == 1"
    >
      <form @submit="handleSubmit($event, onSubmit)" method="post">
        <div class="form-floating mb-3">
          <Field
            name="errorId"
            class="form-control"
            placeholder="Punkt i rapport som fejlrettes"
            id="floatingInput"
            v-model="errorId"
          />
          <label for="floatingInput">
            Skriv punktet i rapporten der er udbedret feks. 2.3.4
          </label>

          <ErrorMessage
            class="text-danger field-validation-error"
            name="errorId"
          />
        </div>
        <div class="form-floating mb-3">
          <Field
            type="textarea"
            name="errorDescription"
            class="form-control"
            placeholder="Punkt i rapport som fejlrettes"
            id="formDescriptionInput"
            style="height: 100px"
            v-model="errorDescription"
          />

          <label for="formDescriptionInput">
            Beskriv kort hvad der er udbedret feks. driftsjournal opdateret
          </label>
          <ErrorMessage
            class="text-danger field-validation-error"
            name="errorDescription"
          />
        </div>
        <div class="mb-3" style="margin-top: 25px">
          <div class="float-end">
            <button
              style="padding-top: 11px; margin-top: 0px"
              class="btn btn-primary btn-sm"
              type="submit"
            >
              Registrer
            </button>
          </div>
        </div>
      </form>
    </VeeForm>
    <template v-else>
      <div class="mb-3 mt-3">
        <p>Jeg bekræfter hermed at alle fejl på anlægget er udbedret</p>
      </div>
      <div class="mb-3" style="margin-top: 25px">
        <div class="float-end">
          <button
            style="padding-top: 11px; margin-top: 0px"
            class="btn btn-primary btn-sm"
            @click.prevent="OnAllErrors"
          >
            Registrer
          </button>
        </div>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { Field, Form as VeeForm, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default defineComponent({
  name: 'errorRegistration',
  components: {
    Field,
    VeeForm,
    ErrorMessage
  },
  props: {
    allowPartly: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    const errorId = ref<string>();
    const errorDescription = ref<string>();
    const allErrors = ref<boolean>(false);
    const correctionType = ref<number>(props.allowPartly ? 1 : 2);
    const registreSchema = yup.object({
      allowClose: yup.boolean(),
      errorId: yup.string().required('Punkt skal udfyldes'),
      errorDescription: yup.string().required('Beskrivelse skal udfyldes'),
      correctionType: yup.number().when('allErrors', {
        is: true,
        then: yup.number().required()
      })
    });
    const onSubmit = (values: any, formState: any): void => {
      const note = allErrors.value
        ? ''
        : `${errorId.value} - ${errorDescription.value}`;
      emit('onAdd', note);
      formState.resetForm({
        values: {
          errorId: '',
          errorDescription: ''
        }
      });
    };
    const OnAllErrors = (): void => {
      emit('onDone');
    };
    watch(
      () => correctionType.value,
      () => {
        allErrors.value = !allErrors.value;
      }
    );
    return {
      errorId,
      errorDescription,
      correctionType,
      allErrors,
      registreSchema,
      onSubmit,
      OnAllErrors
    };
  }
});
</script>
