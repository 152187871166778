import ErrorCorrectionViewModel from '@/Model/ErrorCorrectionViewModel';
import axios, { AxiosResponse } from 'axios';
import IComandResultTyped from '@/Model/IComandResultTyped';
import IComandResult from '@/Model/IComandResult';

export default (): any => {
  const load = async (id: string): Promise<ErrorCorrectionViewModel> => {
    return new Promise<ErrorCorrectionViewModel>((resolve, reject) => {
      axios
        .get(`/api/ErrorCorrection/${id}`)
        .then(
          (
            response: AxiosResponse<
              IComandResultTyped<ErrorCorrectionViewModel>
            >
          ) => {
            if (response.status == 200) {
              if (response.data.success) {
                if (response.data.data === null) {
                  reject('blev ikke fundet');
                } else {
                  resolve(response.data.data);
                }
              } else {
                reject(response.data.errors);
              }
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  const update = async (
    model: ErrorCorrectionViewModel,
    user: string
  ): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      axios
        .put(`/api/ErrorCorrection/${model.reference}`, { model, user })
        .then((response: AxiosResponse<IComandResult>) => {
          if (response.status == 200) {
            resolve();
          } else {
            reject(response.data.errors);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return { update, load };
};
