<template>
  <VeeForm
    v-slot="{ handleSubmit }"
    :validation-schema="registreSchema"
    as="div"
    id="registreForm"
  >
    <form
      @submit="handleSubmit($event, onSubmit)"
      method="post"
      action="/api/Account/registre"
    >
      <div class="form-floating mb-3">
        <Field
          name="name"
          class="form-control"
          placeholder="Navn"
          v-model="name"
        />
        <label for="floatingInput">Navn</label>
        <ErrorMessage class="text-danger field-validation-error" name="name" />
      </div>
      <div class="form-floating mb-3">
        <Field
          name="email"
          class="form-control"
          type="email"
          placeholder="Email"
          v-model="email"
        />
        <label for="floatingInput">Email adresse</label>
        <ErrorMessage class="text-danger field-validation-error" name="email" />
      </div>
      <div class="mb-3" style="margin-top: 25px">
        <div class="float-end">
          <button
            style="padding-top: 11px; margin-top: 0px"
            class="btn btn-primary btn-sm"
            type="submit"
          >
            Næste
          </button>
        </div>
      </div>
    </form>
  </VeeForm>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import { Field, Form as VeeForm, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default defineComponent({
  name: 'userRegistration',
  components: {
    Field,
    VeeForm,
    ErrorMessage
  },
  setup(_, { emit }) {
    const name = ref<string>('');
    const email = ref<string>('');
    const registreSchema = yup.object({
      name: yup.string().required('Navn skal udfyldes'),
      email: yup
        .string()
        .required('Email skal udfyldes')
        .email('Ikke valid Email')
    });
    const onSubmit = () => {
      emit('onRegister', { name: name.value, email: email.value, valid: true });
    };
    return { registreSchema, onSubmit, name, email };
  }
});
</script>
