
import {
  defineAsyncComponent,
  defineComponent,
  onMounted,
  ref,
  toRaw
} from 'vue';
import { useRouter } from 'vue-router';
import useErrorCorrection from './useErrorCorrection';
import ErrorCorrectionViewModel from '@/Model/ErrorCorrectionViewModel';
import { OidcAuth } from 'vue-oidc-client/vue3';
import { configureOidc } from '@/services/auth.service';
import ErrorCorrectionNoteViewModel from '@/Model/ErrorCorrectionNoteViewModel';
import toDateString from '@/utillities/toDateString';
import { appState } from '../../store';

export default defineComponent({
  name: 'ErrorCorrection',
  components: {
    UserRegistration: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "errorCorrection" */ './components/userRegistration.vue'
        )
    ),
    ErrorRegistration: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "errorCorrection" */ './components/errorRegistration.vue'
        )
    )
  },
  setup() {
    const name = ref<string>('');
    const email = ref<string>('');
    const userKnown = ref<boolean>(false);
    const allowPartly = ref<boolean>(false);
    const receipt = ref<boolean>(false);
    const isExpired = ref<boolean>(false);
    const expirationDate = ref();
    const model = ref<ErrorCorrectionViewModel>();
    const { push, currentRoute } = useRouter();
    const { load, update } = useErrorCorrection();
    const onSubmit = (parms: any) => {
      userKnown.value = parms.valid;
      name.value = parms.name;
      email.value = parms.email;
    };
    const addErrorRegistration = (note: string) => {
      if (model.value) {
        const noteModel = new ErrorCorrectionNoteViewModel();
        noteModel.description = note;
        const error: ErrorCorrectionViewModel = toRaw(model.value);
        error.notes =
          error.notes !== null
            ? error?.notes
            : new Array<ErrorCorrectionNoteViewModel>();
        error?.notes.push(noteModel);
        const userString = `${name.value} ${email.value}`;
        update(error, userString).then(() => {
          load(error?.reference).then((result: ErrorCorrectionViewModel) => {
            model.value = result;
          });
        });
      }
    };
    const allErrorDone = () => {
      if (model.value) {
        const noteModel = new ErrorCorrectionNoteViewModel();
        noteModel.description = 'Alle fejl på anlæget bekræftet udbedret';
        const error: ErrorCorrectionViewModel = toRaw(model.value);
        error.notes =
          error.notes !== null
            ? error?.notes
            : new Array<ErrorCorrectionNoteViewModel>();
        error.allErrorsCorrected = true;
        const userString = `${name.value} ${email.value}`;
        update(error, userString).then(() => {
          load(error?.reference).then((result: ErrorCorrectionViewModel) => {
            model.value = result;
          });
        });
        receipt.value = true;
      }
    };
    onMounted(() => {
      appState.setTitle('Bekræftigelse af fejlretning');
      if (
        !currentRoute.value.params ||
        !currentRoute.value.params.id ||
        currentRoute.value.params.id === ''
      ) {
        push({ name: 'errorCorrection_error' });
      } else {
        const id = Array.isArray(currentRoute.value.params.id)
          ? currentRoute.value.params.id[0]
          : currentRoute.value.params.id.toString();
        if (id !== null) {
          load(id)
            .then((result: ErrorCorrectionViewModel) => {
              configureOidc()
                .then((idsvAuth: OidcAuth) => {
                  if (idsvAuth.isAuthenticated) {
                    push({
                      name: 'inspection_documents',
                      params: {
                        id: result.inspection.customerId,
                        inspection: result.inspection.id
                      }
                    });
                  } else {
                    model.value = result;
                    allowPartly.value = model.value.allowPartlyCorrection;
                    expirationDate.value = model.value.expiresAt;
                    isExpired.value = model.value.isExpired;
                  }
                })
                .catch(() => {
                  push({ name: 'errorCorrection_error' });
                });
            })
            .catch(() => {
              push({ name: 'errorCorrection_error' });
            });
        } else {
          push({ name: 'errorCorrection_error' });
        }
      }
    });
    return {
      onSubmit,
      allErrorDone,
      model,
      userKnown,
      name,
      email,
      addErrorRegistration,
      toDateString,
      allowPartly,
      receipt,
      isExpired,
      expirationDate
    };
  }
});
