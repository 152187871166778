
import { defineComponent, ref } from 'vue';
import { Field, Form as VeeForm, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default defineComponent({
  name: 'userRegistration',
  components: {
    Field,
    VeeForm,
    ErrorMessage
  },
  setup(_, { emit }) {
    const name = ref<string>('');
    const email = ref<string>('');
    const registreSchema = yup.object({
      name: yup.string().required('Navn skal udfyldes'),
      email: yup
        .string()
        .required('Email skal udfyldes')
        .email('Ikke valid Email')
    });
    const onSubmit = () => {
      emit('onRegister', { name: name.value, email: email.value, valid: true });
    };
    return { registreSchema, onSubmit, name, email };
  }
});
