<template>
  <div class="row justify-content-md-center mt-3" v-if="model">
    <div id="errorCorrection" :class="[userKnown ? 'col-md-6' : 'col-md-4']" v-if="!isExpired">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">
            Registrering af udbedret fejl på {{ model.inspection.name }}
          </h5>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ model.inspection.address }}
            <span v-if="model.inspection.location">
              , {{ model.inspection.location }}
            </span>
          </h6>
          <div class="mb-3 mt-3" v-if="receipt">
            <p>
              Din Registrering er modtaget, du vil modtage en godkendelse inden
              for kort tid
            </p>
          </div>
          <template v-else>
            <ErrorRegistration
              @onAdd="addErrorRegistration"
              @onDone="allErrorDone"
              v-if="userKnown"
              :allowPartly="allowPartly"
            >
              Registrant:{{ name }} {{ email }}
            </ErrorRegistration>

            <UserRegistration v-else @onRegister="onSubmit">
              <template v-slot:title>
                Registrering af udbedret fejl på {{ model.inspection.name }}
              </template>
              <template v-slot:subtitle>
                {{ model.inspection.address }}
                <span v-if="model.inspection.location">
                  , {{ model.inspection.location }}
                </span>
              </template>
            </UserRegistration>
          </template>
        </div>
      </div>
    </div>
    <div id="errorCorrection" :class="[userKnown ? 'col-md-6' : 'col-md-4']" v-if="isExpired">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">
            Registrering af udbedret fejl på {{ model.inspection.name }}
          </h5>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ model.inspection.address }}
            <span v-if="model.inspection.location">
              , {{ model.inspection.location }}
            </span>
          </h6>
          <div class="mb-3 mt-3">
            <p>
              Fristen for tilbagemelding på, at B-fejl er udbedret, er overskredet den {{ toDateString(expirationDate) }}, og det er derfor ikke længere muligt at tilbagemelde på linket.  
            </p>
            <p>
              Du bedes derfor bekræfte udbedring af fejl pr. mail til <a href="mailto:rapport.rmg@kiwa.com">rapport.rmg@kiwa.com</a>. Vi vil herefter fremsende bekræftelse på godkendelse af anlægget.
              Ved tvivlsspørgsmål kontakt også venligst <a href="mailto:rapport.rmg@kiwa.com">rapport.rmg@kiwa.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4" v-if="model.notes && userKnown && allowPartly">
      <h6 class="">Tidligere Registreringer</h6>
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item p-0"
          v-for="note in model.notes"
          :key="note.id"
        >
          <p class="text-start mb-1 mt-1">{{ note.description }}</p>
          <p class="text-start mb-0">
            <small>
              {{ toDateString(note.closed) }} af: {{ note.closedBy }}
            </small>
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineAsyncComponent,
  defineComponent,
  onMounted,
  ref,
  toRaw
} from 'vue';
import { useRouter } from 'vue-router';
import useErrorCorrection from './useErrorCorrection';
import ErrorCorrectionViewModel from '@/Model/ErrorCorrectionViewModel';
import { OidcAuth } from 'vue-oidc-client/vue3';
import { configureOidc } from '@/services/auth.service';
import ErrorCorrectionNoteViewModel from '@/Model/ErrorCorrectionNoteViewModel';
import toDateString from '@/utillities/toDateString';
import { appState } from '../../store';

export default defineComponent({
  name: 'ErrorCorrection',
  components: {
    UserRegistration: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "errorCorrection" */ './components/userRegistration.vue'
        )
    ),
    ErrorRegistration: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "errorCorrection" */ './components/errorRegistration.vue'
        )
    )
  },
  setup() {
    const name = ref<string>('');
    const email = ref<string>('');
    const userKnown = ref<boolean>(false);
    const allowPartly = ref<boolean>(false);
    const receipt = ref<boolean>(false);
    const isExpired = ref<boolean>(false);
    const expirationDate = ref();
    const model = ref<ErrorCorrectionViewModel>();
    const { push, currentRoute } = useRouter();
    const { load, update } = useErrorCorrection();
    const onSubmit = (parms: any) => {
      userKnown.value = parms.valid;
      name.value = parms.name;
      email.value = parms.email;
    };
    const addErrorRegistration = (note: string) => {
      if (model.value) {
        const noteModel = new ErrorCorrectionNoteViewModel();
        noteModel.description = note;
        const error: ErrorCorrectionViewModel = toRaw(model.value);
        error.notes =
          error.notes !== null
            ? error?.notes
            : new Array<ErrorCorrectionNoteViewModel>();
        error?.notes.push(noteModel);
        const userString = `${name.value} ${email.value}`;
        update(error, userString).then(() => {
          load(error?.reference).then((result: ErrorCorrectionViewModel) => {
            model.value = result;
          });
        });
      }
    };
    const allErrorDone = () => {
      if (model.value) {
        const noteModel = new ErrorCorrectionNoteViewModel();
        noteModel.description = 'Alle fejl på anlæget bekræftet udbedret';
        const error: ErrorCorrectionViewModel = toRaw(model.value);
        error.notes =
          error.notes !== null
            ? error?.notes
            : new Array<ErrorCorrectionNoteViewModel>();
        error.allErrorsCorrected = true;
        const userString = `${name.value} ${email.value}`;
        update(error, userString).then(() => {
          load(error?.reference).then((result: ErrorCorrectionViewModel) => {
            model.value = result;
          });
        });
        receipt.value = true;
      }
    };
    onMounted(() => {
      appState.setTitle('Bekræftigelse af fejlretning');
      if (
        !currentRoute.value.params ||
        !currentRoute.value.params.id ||
        currentRoute.value.params.id === ''
      ) {
        push({ name: 'errorCorrection_error' });
      } else {
        const id = Array.isArray(currentRoute.value.params.id)
          ? currentRoute.value.params.id[0]
          : currentRoute.value.params.id.toString();
        if (id !== null) {
          load(id)
            .then((result: ErrorCorrectionViewModel) => {
              configureOidc()
                .then((idsvAuth: OidcAuth) => {
                  if (idsvAuth.isAuthenticated) {
                    push({
                      name: 'inspection_documents',
                      params: {
                        id: result.inspection.customerId,
                        inspection: result.inspection.id
                      }
                    });
                  } else {
                    model.value = result;
                    allowPartly.value = model.value.allowPartlyCorrection;
                    expirationDate.value = model.value.expiresAt;
                    isExpired.value = model.value.isExpired;
                  }
                })
                .catch(() => {
                  push({ name: 'errorCorrection_error' });
                });
            })
            .catch(() => {
              push({ name: 'errorCorrection_error' });
            });
        } else {
          push({ name: 'errorCorrection_error' });
        }
      }
    });
    return {
      onSubmit,
      allErrorDone,
      model,
      userKnown,
      name,
      email,
      addErrorRegistration,
      toDateString,
      allowPartly,
      receipt,
      isExpired,
      expirationDate
    };
  }
});
</script>
<style lang="scss" scoped>
#errorCorrection {
  &.col-md-6 {
    transition: all 0.5s linear;
  }
}
</style>
